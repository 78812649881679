import { Button } from '.';

export const ManageFamilyMemberButton = ({ doAction, text, icon, ...props }) => {
  return (
    <Button
      type="text"
      icon={icon}
      onClick={(event) => {
        event.stopPropagation();
        doAction();
      }}
      {...props}
    >
      {text}
    </Button>
  );
};
