import { useQueryClient } from '@tanstack/react-query';
import {
  URL_GET_APPLICATION,
  URL_GET_INCOMPLETE_APPLICATION,
  URL_NOTIFY_AGGREMENT_ATTACHED,
} from 'api/application/constants';
import { usePostNoMutate } from 'api/application/use-post-no-mutate';
import { URL_UPLOAD_FILE_AGGREMENT } from 'api/file/constants';
import { Button } from 'components/button';
import { UploadButton } from 'components/button/upload-button';
import { Form } from 'components/form/form';
import { FormItem } from 'components/form/form-item';
import { Modal } from 'components/modal';
import { VerticalSpace } from 'components/space/vertical-space';
import { UploadApplicationDocument } from 'components/upload/upload-application-documents';
import { DOCUMENT_TYPES } from 'helpers/constants';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export const UploadSubmitDocument = () => {
  const queryClient = useQueryClient();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [notify, setNotify] = useState(false);
  const cityzenData = Form.useWatch(['citizenData', 'citizenInfo'], { preserve: true });

  const canAttachAgreement = Form.useWatch('canAttachAgreement', { preserve: true });
  const agreementFilesChecksum = Form.useWatch('agreementFilesChecksum', { preserve: true });

  const [form] = Form.useForm();
  const onFinish = () => {
    setNotify(true);
  };

  usePostNoMutate(
    URL_NOTIFY_AGGREMENT_ATTACHED,
    {
      applicationId: params.id,
    },
    {
      enabled: !!(notify && params.id),
      onSuccess: () => {
        setNotify(false);
        setOpen(false);
        queryClient.invalidateQueries({
          queryKey: [
            params.status && params.status === 'incomplete' ? URL_GET_INCOMPLETE_APPLICATION : URL_GET_APPLICATION,
          ],
        });
        Modal.success({
          title: 'Շնորհակալություն',
          content: 'Փաստաթուղթը հաջողությամբ կցվել է։',
          footer: false,
          closable: true,
        });
      },
    }
  );

  useEffect(() => {
    if (agreementFilesChecksum && agreementFilesChecksum.length) {
      form.setFieldValue('agreementFilesChecksum', agreementFilesChecksum);
    }
  });
  return (
    <>
      <UploadButton
        text={canAttachAgreement ? 'Կցել թերթիկը' : 'Դիտել կցված թերթիկը'}
        onClick={() => setOpen(true)}
        block
        disabled={false}
      />
      <Modal
        className="project-modal"
        open={open}
        footer={false}
        onCancel={() => setOpen(false)}
        width={788}
        maxWidth={'90%'}
      >
        <Form
          name="upload-documents"
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          requiredMark={false}
          disabled={false} /** need this because the view form set as disabled which affect on this one also */
        >
          <VerticalSpace>
            <UploadApplicationDocument
              text="Կցել թերթիկը"
              checksumName="agreementFilesChecksum"
              attachedDocumentType={DOCUMENT_TYPES.FAMILY_MEMEBER}
              infoText="Խնդրում ենք կցել հավաստման թերթիկը՝ ստորագրված Ձեր և ընտանիքի անդամների կողմից"
              noInfo={!canAttachAgreement}
              userData={cityzenData}
              uploadUrl={URL_UPLOAD_FILE_AGGREMENT}
              viewMode={!canAttachAgreement}
              disabled={!canAttachAgreement}
            />
            {canAttachAgreement && (
              <FormItem wrapperCol={{ span: 6, offset: 9 }}>
                <Button htmlType="submit" block type="primary">
                  Հաստատել
                </Button>
              </FormItem>
            )}
          </VerticalSpace>
        </Form>
      </Modal>
    </>
  );
};
