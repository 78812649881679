import { useNavigate } from 'react-router-dom';
import { Button, SubmitButton } from '../../components/button';
import { ApplicationFor } from '../../components/form/application/application-for';
import { ApplicationNotForMe } from '../../components/form/application/application-not-for-me';
import { VerticalSpace } from '../../components/space/vertical-space';
import { Title } from '../../components/typography';
import { COLORS, PATHS } from '../../helpers/constants';
import { Card, Col, Modal, Row, Space } from 'antd';
import { FormItem } from 'components/form/form-item';
import { Checkbox } from 'components/checkbox';
import { Form } from 'components/form/form';
import { useState } from 'react';
import { ConfirmStep } from './confirm-step';
import { InitialConfirmStep } from './initial-confirm-step';
import { useApplicationForText } from 'hooks/application/use-application-for-text';

export const FirstStep = () => {
  const [confirm, setConfirm] = useState(false);

  const form = Form.useFormInstance();
  const navigate = useNavigate();
  const confirmationModal = () => {
    form
      .validateFields()
      .then(() => {
        setConfirm(true);
      })
      .catch((errorInfo) => {
        errorInfo.errorFields.forEach((item) => {
          form.scrollToField(item.name);
          return;
        });
      });
  };
  return (
    <VerticalSpace size="large">
      <Title level={1}>Դիմումը լրացնում եմ</Title>
      <ApplicationFor />
      <ApplicationNotForMe />
      <InitialConfirmStep />
      <Modal
        closable
        title={<Title color={COLORS.PRIMARY.BLUE}>Լրացնելով դիմումը՝</Title>}
        footer={
          <div style={{ textAlign: 'left' }}>
            <FormItem propName="checked" name="aggrementForInfo" rules={[{ required: true }]}>
              <Checkbox.Group>
                <Checkbox value={true}>{`Հավաստիացնում ${useApplicationForText(
                  'ենք',
                  'եմ'
                )}, որ ներկայացված տվյալները ճիշտ են`}</Checkbox>
              </Checkbox.Group>
            </FormItem>
            <Row align="center" justify="center">
              <Col span={8}>
                <Space size="middle" align="center" style={{ width: '100%', justifyContent: 'center' }}>
                  <Button type="primary" shape="round" size="large" onClick={() => form.submit()}>
                    Հաստատել
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        }
        open={confirm}
        onCancel={() => setConfirm(false)}
        width="70%"
        destroyOnClose
      >
        <Card bordered={true} style={{ overflow: 'auto', height: '50vh' }}>
          <ConfirmStep />
        </Card>
      </Modal>
      {!confirm && (
        <SubmitButton submitText="Հաջորդ" onCancel={() => navigate(PATHS.APPLICATIONS)} onApprove={confirmationModal} />
      )}
    </VerticalSpace>
  );
};
