import { Collapse } from 'antd';
import { Input } from '../../../input';
import { VerticalSpace } from '../../../space/vertical-space';
import { Form } from '../../form';
import { FormItem } from '../../form-item';
import { PersonDataForm } from '../../person-data-form';
import { EducationFormView } from '../education-form/view';
import { MilitaryFormView } from '../military-form/view';
import { files } from 'pages/application/constants';
import { setFamilyEducation } from './family-member-form';

const { Panel } = Collapse;

export const setFormItemProp = (key) => ({
  fname: {
    name: [key, 'fname'],
    readOnly: true,
  },
  lname: {
    name: [key, 'lname'],
    readOnly: true,
  },
  patronymicName: {
    name: [key, 'patronymicName'],
    readOnly: true,
  },
  ssn: {
    name: [key, 'ssn'],
    rules: [{ required: true, min: 10, max: 10, message: 'Տեղեկատվությունը սխալ է լրացված' }],
    readOnly: true,
  },
  birthdate: {
    name: [key, 'birthdate'],
    readOnly: true,
  },
  isApproved: {
    name: [key, 'isApproved'],
  },
  contact: {
    email: {
      name: [key, 'email'],
      rules: [{ required: true }, { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' }],
      readOnly: true,
    },
  },
});

export const FamilyFormFormView = ({ field }) => {
  const form = Form.useFormInstance();
  const firstName = form.getFieldValue(['familyData', 'familyMembers', field.name, 'fname']);
  const lastName = form.getFieldValue(['familyData', 'familyMembers', field.name, 'lname']);

  const personData = setFormItemProp(field.name);

  return (
    <Collapse defaultActiveKey={[field.name]} key={field.name}>
      <Panel header={firstName ? `${firstName} ${lastName}` : 'Ընտանիքի անդամի տվյալներ'} key={field.name}>
        <VerticalSpace>
          <PersonDataForm
            title="Ընտանիքի անդամի տվյալներ"
            formItemProp={personData}
            formItemContactProp={personData.contact}
            viewMode
          />
          <FormItem label="Ազգակցական կապ" name={[field.name, 'relativeType']} required wrapperCol={{ span: 8 }}>
            <Input />
          </FormItem>
          <EducationFormView
            parent={['familyData', 'familyMembers']}
            formItemEducation={setFamilyEducation(field.name)}
          />
          <MilitaryFormView
            parent={['familyData', 'familyMembers']}
            formItemMilitary={{
              isMilitaryService: {
                name: [field.name, 'isMilitaryService'],
              },
              militaryServiceFilesChecksum: {
                name: [field.name, files, 'militaryServiceFilesChecksum'],
              },
            }}
          />
        </VerticalSpace>
      </Panel>
    </Collapse>
  );
};
