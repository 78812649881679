import { FormSectionCard } from '../../../card/form-section-card';
import { VerticalSpace } from '../../../space/vertical-space';
import { UtilityItem } from './item';

export const UtilityAccountsForm = ({ formItemUtility = {} }) => {
  return (
    <FormSectionCard title="Բաժանորդային համար">
      <VerticalSpace>
        {Object.keys(formItemUtility).map((item) => (
          <UtilityItem key={item} item={formItemUtility[item]} />
        ))}
      </VerticalSpace>
    </FormSectionCard>
  );
};
