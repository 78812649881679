import { Radio } from 'antd';
import { URL_RESIDENCE_REASONS } from '../../../../api/benefit/constants';
import { useGetFfromBenefit } from '../../../../api/benefit/use-get-dictionary';
import { COLORS, DOCUMENT_TYPES } from 'helpers/constants';
import { FormSectionFullCard } from '../../../card/form-section-full-card';
import { VerticalSpace } from '../../../space/vertical-space';
import { Text } from '../../../typography';
import { FormItem } from '../../form-item';
import { UploadApplicationDocument } from 'components/upload/upload-application-documents';
import { useApplication } from 'context/applicaton-context';
import { files, formItemStepTwoParent } from 'pages/application/constants';

export const BuildingInfo = ({ formItemAddressType = {} }) => {
  const { data } = useGetFfromBenefit(URL_RESIDENCE_REASONS);
  const { submittedData, cityzenData } = useApplication();

  return (
    <FormSectionFullCard>
      <VerticalSpace>
        <FormItem {...formItemAddressType.actualResidenceType}>
          <Radio.Group>
            <VerticalSpace direction="vertical">
              {data.map((item) => (
                <Radio key={item.id} value={item.id}>
                  <Text color={COLORS.PRIMARY.BLUE_DARK}>{item.name}</Text>
                </Radio>
              ))}
            </VerticalSpace>
          </Radio.Group>
        </FormItem>
        <UploadApplicationDocument
          tempApplicationId={submittedData.tempApplicationId}
          checksumName={[formItemStepTwoParent, files, 'residenceFilesChecksum']}
          attachedDocumentType={DOCUMENT_TYPES.FROM_HOME_PLACE}
          infoText="Խնդրում ենք կցել տեղական ինքնակառավարման մարմնի կողմից տրամադրված տեղեկանք"
          viewMode={!cityzenData?.citizenInfo}
          userData={cityzenData?.citizenInfo}
        />
      </VerticalSpace>
    </FormSectionFullCard>
  );
};
