import { Upload } from 'antd';
import { UploadButton } from '../button/upload-button';

export const UploadDocument = ({ actionIcon, text, ...props }) => {
  return (
    <Upload className="document-uploader" showUploadList={false} {...props}>
      <UploadButton disabled={props.disabled} actionIcon={actionIcon} text={text} />
    </Upload>
  );
};
