import { DOCUMENT_TYPES } from '../../../../helpers/constants';
import { files, formItemStepTwoParent } from '../../../../pages/application/constants';
import { FormSectionFullCard } from '../../../card/form-section-full-card';
import { VerticalSpace } from '../../../space/vertical-space';
import { UploadApplicationDocument } from '../../../upload/upload-application-documents';
import { Form } from '../../form';

export const BuildingInfoView = () => {
  const isActualAddressMatch = Form.useWatch(['citizenData', 'actualResidenceType'], { preserve: true });

  return (
    <FormSectionFullCard>
      <VerticalSpace>
        {isActualAddressMatch}
        <UploadApplicationDocument
          checksumName={[formItemStepTwoParent, files, 'residenceFilesChecksum']}
          attachedDocumentType={DOCUMENT_TYPES.FROM_HOME_PLACE}
          infoText="Խնդրում ենք կցել տեղական ինքնակառավարման մարմնի կողմից տրամադրված տեղեկանք"
          viewMode
        />
      </VerticalSpace>
    </FormSectionFullCard>
  );
};
